// ========================================= //
// itemContent
// ========================================= //

[class*='c-item__content'] {
    position: relative;
    z-index: $zindex--content;
    padding: rem(33) rem(28) rem(63);

    @include bp-lt($tablet) {
        padding: rem(20) rem(20) rem(50);
    }

    @include bp-lt($small) {
        padding: rem(14) rem(14) rem(34);
    }

    [class*="c-title"] {
        margin-bottom: rem(20);
        font-family: $sofiaPro;
        font-size: rem(36);
        line-height: rem(42);
        font-weight: 700;
        color: $white;

        @include bp-lt($medium) {
            margin-bottom: rem(14);
            font-size: rem(24);
            line-height: rem(30);
        }

        @include bp-lt($tablet) {
            font-size: rem(18);
            line-height: rem(24);
        }

        &[class*="--medium"] {
            font-size: rem(28);

            @include bp-lt($medium) {
                font-size: rem(24);
            }

            @include bp-lt($small) {
                font-size: rem(20);
            }
        }

        &[class*="--pushed"] {
            margin-left: rem(12);
            margin-bottom: rem(48);
            line-height: rem(28);

            @include bp-lt($medium) {
                margin-bottom: rem(14);
            }

            & + .c-separator {
                margin-bottom: rem(40);

                @include bp-lt($medium) {
                    margin-bottom: rem(16);
                }
            }
        }
    }

    .c-separator {
        width: rem(26);
        height: rem(4);
        margin-bottom: rem(20);
        background: $subBranding;

        @include bp-lt($medium) {
            margin-bottom: rem(16);
        }
    }

    .c-content {
        margin-bottom: rem(20);

        &, p, ul {
            font-family: $merriWeather;
            font-size: rem(14);
            line-height: rem(28);
            font-weight: 300;
            color: $grey--light--10;

            @include bp-lt($medium) {
                font-size: rem(14);
                line-height: rem(20);
            }

            @include bp-lt($tablet) {
                font-size: rem(12);
                line-height: rem(16);
            }

            @include bp-lt($mobileM) {
                font-size: rem(10);
            }
        }

        ul {
            color: $grey--light--00;
            list-style: none;
            margin-left: rem(13);

            li {
                line-height: rem(24);
                margin-bottom: rem(20);
                position: relative;
                padding-left: rem(15);

                @include bp-lt($medium) {
                    font-size: rem(14);
                    line-height: rem(20);
                }

                @include bp-lt($tablet) {
                    font-size: rem(12);
                    line-height: rem(16);
                }

                @include bp-lt($mobileM) {
                    font-size: rem(10);
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: rem(9);
                    left: 0;
                    display: block;
                    vertical-align: middle;
                    width: rem(5);
                    height: rem(5);
                    background: $subBranding;
                    border-radius: 50%;

                    @include bp-lt($medium) {
                        top: rem(8);
                    }

                    @include bp-lt($tablet) {
                        top: rem(6);
                    }

                    @include bp-lt($mobileM) {
                        top: rem(5);
                    }
                }
            }
        }
    }

    [class*='c-linkCst'] {
        position:  absolute;
        bottom: rem(33);
        margin-bottom: 0;
        display: inline;
        line-height: rem(14);

        @include bp-lt($tablet) {
            bottom: rem(20);
            font-size: rem(12);
        }

        @include bp-lt($small) {
            bottom: rem(14);
        }

        @include bp-lt($mobileL) {
            font-size: rem(10);
        }

        &[class*='--white']:before {
            bottom: rem(-4);
            background: rgba($white, .5);
        }
    }

    &[class*='--arrowRight'],
    &[class*='--arrowLeft'] {
        &:after {
            content: '';
            position: absolute;
            top: rem(88);
            display: block;
            border-top: rem(27) solid transparent;
            border-bottom: rem(27) solid transparent;

            @include bp-lt($mobileL) {
                top: rem(58);
                border-top: rem(14) solid transparent;
                border-bottom: rem(14) solid transparent;
            }
        }
    }

    &[class*='--arrowRight']:after {
        right: rem(-26);
        border-left: rem(27) solid;

        @include bp-lt($mobileL) {
            right: rem(-13);
            border-left: rem(14) solid;
        }
    }

    &[class*='--arrowLeft']:after {
        left: rem(-26);
        border-right: rem(27) solid;

        @include bp-lt($small) {
            left: inherit;
            right: rem(-26);
            border-right: none;
            border-left: rem(27) solid;
        }

        @include bp-lt($mobileL) {
            right: rem(-13);
            border-left: rem(14) solid;
        }
    }

    &[class*='--mainBranding'] {
        background: $mainBranding;

        &[class*='--arrowRight']:after { border-left-color: $mainBranding; }
        &[class*='--arrowLeft']:after {
            border-right-color: $mainBranding;

            @include bp-lt($small) {
                border-left-color: $mainBranding;
            }
        }
    }

    &[class*='--alt'] {
        background: $label__product--alt;

        &[class*='--arrowRight']:after { border-left-color: $label__product--alt; }
        &[class*='--arrowLeft']:after {
            border-right-color: $label__product--alt;

            @include bp-lt($small) {
                border-left-color: $label__product--alt;
            }
        }

        .c-content { color: $grey--regular--02; }
    }

    &[class*='--intro'] {
        .c-content,
        .c-content p { color: $white; }
    }

    &[class*="extendTo"] {
        &:before {
            content: '';
            display: block;
            width: 100vw;
            height: 100%;
            position: absolute;
            top: 0;
        }

        &[class*='--mainBranding'] {
            &:before {
                background: $mainBranding;
            }
        }

        &[class*="Left"] {
            &:before {
                left: -100vw;
            }
        }

        &[class*="Right"] {
            &:before {
                right: -100vw;
            }
        }
    }

    &[class*="--about"] {
        margin-top: rem(75);
        padding: rem(60) rem(50);

        @include bp-lt($desktop) {
            padding: rem(40) rem(30);
        }

        @include bp-lt($medium) {
            padding-top: rem(30);
            padding-bottom: rem(30);
        }

        @include bp-lt($small) {
            padding: rem(20);
            margin-top: rem(30);
        }

        p {
            font-size: rem(20);
            margin-left: rem(12);
            font-family: $sofiaPro;

            @include bp-lt($medium) {
                font-size: rem(14);
                line-height: rem(20);
            }

            @include bp-lt($tablet) {
                font-size: rem(12);
                line-height: rem(16);
            }

            @include bp-lt($mobileM) {
                font-size: rem(10);
            }
        }

        [class*="c-button--transparent"] {
            margin-top: rem(35);
            color: $white;
            background-image: url(#{$img}c-icon_arrowRight--white.svg);
            border-color: $grey--09;

            @include bp-lt($medium) {
                margin-top: rem(15);
            }
        }
    }

    &[class*="--b2b"] {
        padding-top: rem(90);
        padding-bottom: rem(90);

        @include bp-lt($small) {
            padding: rem(14) rem(14) rem(34);
            text-align: center;
        }

        [class*="c-title"] {
            color: $mainFont;
            line-height: rem(28);
        }

        .c-separator {
            background-color: $b2b--blue;

            @include bp-lt($small) {
                margin-left: auto;
                margin-right: auto;
            }
        }

        [class*="c-content"] {
            margin-bottom: rem(30);

            p, li {
                color: $mainFont;
            }

            ul {
                list-style: none;
                margin: 0;

                li {
                    padding-left: 0;

                    [class*="c-fonticon"] {
                        margin-right: rem(15);
                        font-size: rem(30);
                        position: relative;
                        top: rem(10);

                        @include bp-lt($small) {
                            display: block;
                            margin: rem(20) auto;
                        }
                    }

                    &:before { display: none; }
                }
            }
        }
    }
}
