// ========================================= //
// global
// ========================================= //

$img                    : '../../media/images/' !default;
$bezierValue            : cubic-bezier(.7, 0, .175, 1);
$bezier                 : all .4s $bezierValue;
$ease                   : all .4s ease;
$ease--long             : all .6s ease;
$layoutWidth            : 90em !default;       //1440px
$gutter                 : rem(7);
