// ========================================= //
// ui
// ========================================= //

::-moz-selection { color: $mainBranding;  background: $subBranding; }
::selection      { color: $mainBranding;  background: $subBranding; }

* {
    outline: none !important;
}

body {
    .c-wrapper__offcanvas {
        position: relative;
        right: 0;
        padding-top: 0;
        transition: $ease;

        &.is-expand {
            margin-top: rem(55);
        }
    }

    .c-stickyNav {
        left: 0;
        transition: $ease;
    }

    &.is-fixed {
        position: fixed;
        overflow: hidden;

        .c-wrapper__offcanvas {
            position: relative;
            right: rem(280);
        }

        .c-stickyNav {
            left: rem(-280);
        }
    }

    &.is-product__selecting {
        &:after {
            @include bp-lt($small) {
                content: '';
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($white, 0.9);
            }
        }
    }
}

.o-table {
    display: table;
    width: 100%;
    height: 100%;
}

[class*='o-table__cell'] {
    display: table-cell;

    &[class*='--valignMiddle'] {
        vertical-align: middle;

        &[class*='--tabletDown'] {
            vertical-align: top;

            @include bp-lt($tablet) {
                vertical-align: middle;
            }
        }
    }

    &[class*='--valignBottom'] {
        vertical-align: bottom;
    }
}

.row {
    max-width: $layoutWidth;

    &.row--full {
        max-width: 100%;
    }

    .row {
        margin: 0 rem(-7);
    }

    [class*='column'] {
        padding-left: $gutter;
        padding-right: $gutter;
    }
}

.c-page__didYouKnow {
    $didYouKnowGutter : 14;

    .row {
        .row {
            margin: 0 rem(-1 * $didYouKnowGutter);

            @include bp-lt($xmedium) {
                margin: 0 rem(-7);
            }
        }

        [class*='column'] {
            padding-left: rem($didYouKnowGutter);
            padding-right: rem($didYouKnowGutter);

            @include bp-lt($xmedium) {
                padding-left: $gutter;
                padding-right: $gutter;
            }
        }
    }
}

#v-cookielaw.v-dark-clean .v-button {
    background: $mainBranding !important;
}
